import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { login } from "../store/slices/auth";
import Input from "../components/Input";
import logo from "../assets/images/logo/logo-main.svg";
import { colors } from "../constants/theme";
import { RootState } from "../store";

// TODO: implement Formik with validation
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const loginError = useSelector((state: RootState) => state.auth.error);
  const isLoading = useSelector((state: RootState) => state.auth.isLoading);

  // const verifyEmail = (text: string): boolean => {
  //   const rgxp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return rgxp.test(text);
  // };

  const handleLogin = () => {
    dispatch(
      login(email, password, () => {
        history.push("/suppliers");
      })
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: "#e8ecf1",
      }}
    >
      <Box
        sx={{
          width: "400px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: "4px",
          shadowBox: "0 0 25px #000",
          padding: "20px",
        }}
      >
        <img src={logo} style={{ marginBottom: "20px" }} alt="Para logo" />

        <Input
          label="E-mail"
          type="email"
          name="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          // error={error.includes('email') || loginError}
        />
        <Input
          label="Password"
          required
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          // error={error.includes('password') || loginError}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleLogin}
          disabled={!password || !email}
          sx={{ my: 2 }}
        >
          {isLoading ? <CircularProgress /> : "Login"}
        </Button>
        {loginError && (
          <Typography variant="caption" sx={{ color: colors.alert }}>
            Incorrect email or password!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LoginPage;
