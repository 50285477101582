import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import demandReducer from "./slices/demand";
import nursesReducer from "./slices/nurses";

const store = configureStore({
  reducer: {
    auth: authReducer,
    nurses: nursesReducer,
    demand: demandReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
