export const LICENSES_LIST = [
  {
    id: "us_drivers_license",
    label: "Driver License",
    route: "drivers",
  },
  {
    id: "us_nursing_license",
    label: "Nurse License",
    route: "nurse",
  },
];
