import React from "react";
import { CircularProgress, TextField, TextFieldProps } from "@mui/material";

type InputProps = {
  loading?: boolean;
} & TextFieldProps;

const Input: React.FunctionComponent<InputProps> = (props) => {
  return (
    <TextField
      variant="standard"
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: props.loading ? (
          <CircularProgress size={10} />
        ) : (
          props.InputProps?.endAdornment
        ),
        ...props.InputProps,
      }}
      margin="dense"
      fullWidth={props.fullWidth ?? true}
    />
  );
};

export default Input;
