import React from "react";
import { Route, Redirect, RouteProps, RouteComponentProps } from "react-router-dom";
import { isAuthenticated } from "../../utils/auth";
import { LOGIN } from "../../constants/routes";

const PrivateRoute = (props: RouteProps) => {
  const { children, ...rest } = props;
  return (
    <Route
      {...rest}
      render={({ location }: RouteComponentProps) =>
        isAuthenticated() ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
