import { ThemeOptions } from "@mui/material/styles";

export const colors = {
  primary: "#1963b0",
  primaryLight: "rgba(25, 99, 176, 0.31)",
  secondary: "#CED4DB",
  alert: "#E33A4F",
  alertLight: "rgba(227, 58, 79, 0.3)",
  success: "#17C122",
  successLight: "rgba(23, 193, 34, 0.3)",
  warning: "#FFB843",
  warningLight: "rgba(255, 184, 68, 0.3)",
  white: "#fff",
};

export const theme: ThemeOptions = {
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.alert,
    },
    success: {
      main: colors.success,
    },
    contrastThreshold: 3,
    tonalOffset: 0.08,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ["CircularStd-Book", "-apple-system", "Helvetica Neue", "Arial", "sans-serif"].join(
      ","
    ),
  },
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          height: 45,
          color: colors.white,
          boxShadow: "none",
          textTransform: "initial",
          fontFamily: "CircularStd-Medium",
          fontWeight: 500,
          "&:hover": {
            boxShadow: "none",
          },
        },
        text: {
          color: colors.primary,
          "&:hover": {
            background: "initial",
          },
        },
        outlinedPrimary: {
          color: colors.primary,
        },
        containedSecondary: {
          color: colors.white,
        },
        textSecondary: {
          color: colors.secondary,
        },
      },
    },
  },
};
