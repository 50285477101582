import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import objectSupport from "dayjs/plugin/objectSupport";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

dayjs.locale({
  ...en,
  weekStart: 1,
});
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
