import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Button, Typography } from "@mui/material";
import { RootState } from "../../../store";
import {
  createVaccinationCredentialStatus,
  getNurseVaccinations,
} from "../../../store/slices/nurses";
import { Vaccination } from "../../../types";
import Subtable from "../../../components/Subtable";
import { VaccinationCard } from "../../../components/VaccinationCard";
import { generateStatusStringByKey, generateStatusStyles } from "../../../utils";

const NurseVaccinationsBlock = ({ nurseId }: { nurseId: string }) => {
  const { nurseVaccinations, vaccinationsLoading } = useSelector(
    (state: RootState) => state.nurses
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNurseVaccinations(nurseId));
  }, [dispatch, nurseId]);

  const [selectedVaccination, setSelectedVaccination] = useState<Vaccination | null>(null);
  const handleShowVaccination = (vaccination: Vaccination) => {
    setSelectedVaccination(vaccination);
  };

  const handleCloseVaccination = () => {
    setSelectedVaccination(null);
  };

  const tableColumns = [
    {
      title: "Vaccination name",
      accessor: (vaccination: Vaccination) => {
        return <b>{vaccination.vaccine.vaccine}</b>;
      },
    },
    {
      title: "Date",
      accessor: (vaccination: Vaccination) => {
        return dayjs(vaccination.vaccination_date).format("LL");
      },
    },
    {
      title: "Status",
      accessor: (vaccination: Vaccination) => {
        const status = generateStatusStringByKey(
          vaccination.current_health_record_status.health_record_status.health_record_status_type
            .health_record_status_type_key
        );
        return (
          <Typography variant="body1" sx={generateStatusStyles(status)}>
            {status}
          </Typography>
        );
      },
      align: "right",
    },
    {
      title: "",
      accessor: (vaccination: Vaccination) => {
        return (
          <Button onClick={() => handleShowVaccination(vaccination)} style={{ width: "120" }}>
            View
          </Button>
        );
      },
      align: "right",
      width: "100px",
    },
  ];

  const handleSubmit = useCallback(
    (vaccination: Vaccination) => {
      dispatch(
        createVaccinationCredentialStatus(vaccination, () => {
          setSelectedVaccination(null);
          dispatch(getNurseVaccinations(nurseId));
        })
      );
    },
    [dispatch, nurseId]
  );

  return (
    <>
      <Subtable
        loading={vaccinationsLoading}
        data={nurseVaccinations}
        title="Vaccinations"
        notFoundText="No vaccinations found"
        columns={tableColumns}
      />

      {selectedVaccination && (
        <VaccinationCard
          vaccination={selectedVaccination}
          handleCancel={handleCloseVaccination}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default NurseVaccinationsBlock;
