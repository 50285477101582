import React from "react";
import dayjs from "dayjs";
import { View } from "../../types";
import styles from "./list-view.module.scss";
import { Button } from "@mui/material";

type ListViewProps<T> = {
  view: View[];
  list: T[];
  idField: string;
  handleClick: (element: T) => void;
};

const ListView = <T extends object>(props: ListViewProps<T>) => {
  const { view, list, handleClick } = props;

  const handleButtonClick = (element: T): void => {
    handleClick(element);
  };

  return (
    <>
      <div
        className={styles.TableHeader}
        style={{
          gridTemplateColumns: `repeat(${view.length}, 1fr)`,
        }}
      >
        {view.map(({ label }, idx) => {
          return (
            <div className={styles.TableLabel} key={idx}>
              {label}
            </div>
          );
        })}
      </div>
      {list.map((elem, index) => {
        return (
          <div
            className={styles.UserBlockView}
            style={{
              gridTemplateColumns: `repeat(${view.length}, 1fr)`,
            }}
            key={index}
          >
            {view.map(({ name, type, format, btnLabel }, idx) => {
              if (type === "text" && name) {
                return (
                  <div key={idx}>
                    {name.split(".").length > 1
                      ? name.split(".").reduce((p, c) => c, "elem")
                      : (elem as Record<string, string>)[name]}
                  </div>
                );
              }
              if (type === "btn") {
                return (
                  <Button
                    key={idx}
                    onClick={() => handleButtonClick(elem)}
                    sx={{ justifySelf: "flex-end" }}
                    variant="outlined"
                  >
                    {btnLabel === "Confirm"
                      ? (elem as Record<string, string>).serviceable
                        ? "Edit"
                        : "Confirm"
                      : btnLabel || ""}
                  </Button>
                );
              }

              if (type === "date" && name) {
                return (
                  <div key={idx}>
                    {dayjs((elem as Record<string, string>)[name]).format(format)}
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      })}
    </>
  );
};

export { ListView };
