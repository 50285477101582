import { createSlice } from "@reduxjs/toolkit";
import { authService } from "../../services/auth.service";
import api from "../../services/api";
import { NurseListItem } from "../../types";
import { getErrorMessage } from "../../utils";
import { AppDispatch } from "../store";

type AuthState = {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: NurseListItem | null;
  error: string | null;
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    isAuthenticated: localStorage.getItem("_t") !== null,
    user: null,
    error: null,
  } as AuthState,
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state) => {
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    logoutRequest: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, logoutRequest } = authSlice.actions;

export const login =
  (email: string, password: string, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(loginRequest());

    try {
      const token = await authService.login(email, password);
      if (token) {
        dispatch(loginSuccess());
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        api.interceptors.response.use(
          (response) => response,
          (error) => {
            const { status } = error.response;
            if (status === 401) {
              dispatch(logout());
              return Promise.reject(error);
            }
            return Promise.reject(error);
          }
        );

        callback?.();
      }
    } catch (error) {
      dispatch(loginFailure(getErrorMessage(error)));
    }
  };

export const logout = (callback?: () => void) => async (dispatch: AppDispatch) => {
  dispatch(logoutRequest());
  api.defaults.headers.common["Authorization"] = null;
  callback?.();
};

export default authSlice.reducer;
