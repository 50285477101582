import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getCredentialStatusTypes, getLicenseImages } from "../../store/slices/nurses";
import { RootState } from "../../store";
import { USLicense } from "../../types";
import styles from "./LicenseCard.module.scss";
import { isDrivingLicense } from "../../utils/typeGuards";

type LicenseCardProps = {
  license: USLicense;
  accountId: string;
  handleCancel: () => void;
  handleSubmit: (license: USLicense, accountId: string) => void;
};

const LicenseCard = (props: LicenseCardProps) => {
  const { license, accountId, handleCancel, handleSubmit } = props;
  const [viewData, setViewData] = useState<USLicense>(license);
  const dispatch = useDispatch();
  const { statusTypes, licenseImages } = useSelector((state: RootState) => state.nurses);
  console.log("License is", license, licenseImages);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const statusType = statusTypes.find((s) => s.credential_status_type_key === value);
    console.log("Status type", statusType, license.current_credential_status);
    if (statusType) {
      setViewData({
        ...viewData,
        current_credential_status: {
          credential_status: {
            credential_status_type: statusType,
          },
        },
      });
    }
  };

  useEffect(() => {
    dispatch(getCredentialStatusTypes());
    dispatch(getLicenseImages(license, accountId));
  }, [dispatch, license, accountId]);

  return (
    <Dialog open={true} maxWidth="md" onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {isDrivingLicense(viewData) ? "Driver License" : "Nurse License"}
      </DialogTitle>
      <DialogContent>
        <div className={styles.Container}>
          <div className={styles.TopBlockWrapper}>
            <div className={styles.InfoBlock}>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>id first name</div>
                <div className={styles.Value}>{viewData.first_name}</div>
              </div>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>id last name</div>
                <div className={styles.Value}>{viewData.last_name}</div>
              </div>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>license number</div>
                <div className={styles.Value}>
                  {isDrivingLicense(viewData)
                    ? viewData.drivers_license_number
                    : viewData.us_nursing_license_number}
                </div>
              </div>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>expiration date</div>
                <div className={styles.Value}>{dayjs(viewData.expiration_date).format("LL")}</div>
              </div>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>issuing state</div>
                <div className={styles.Value}>{viewData.issuing_region.region_name}</div>
              </div>
            </div>
            {licenseImages.length > 1 && (
              <div className={styles.ImagesBlock}>
                <div className={styles.ImageElement}>
                  <img src={licenseImages[0].license_image_url} alt="front" />
                  <div className={styles.ImageText}>{licenseImages[0].image_orientation}</div>
                </div>
                <div className={styles.ImageElement}>
                  <img src={licenseImages[1].license_image_url} alt="back" />
                  <div>{licenseImages[1].image_orientation}</div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.BottomBlockWrapper}>
            <Select
              value={
                viewData.current_credential_status.credential_status.credential_status_type
                  .credential_status_type_key
              }
              onChange={handleSelect}
              style={{ width: "300px" }}
            >
              {statusTypes
                .filter(
                  (status) =>
                    status.credential_status_source.credential_status_source_key === "para"
                )
                .map((status) => (
                  <MenuItem value={status.credential_status_type_key}>
                    {status.credential_status_type}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSubmit(viewData, accountId)}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { LicenseCard };
