import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Facility } from "../../types";
import { RootState } from "../../store";
import { getOrganizationFacilities, createFacilityTaxonomyCodes } from "../../store/slices/demand";
import { FacilityCard } from "../../components/FacilityCard";
import { ListView } from "../../components/ListView";
import styles from "./facilities.module.scss";
import { TaxonomyCodeType } from "../../components/Autocomplete";
import ApplicationLayout from "../../layout/ApplicationLayout";

const FACILITIES_VIEW = [
  { label: "facility name", type: "text", name: "facility_name" },
  { label: "street address", type: "text", name: "address.route" },
  { label: "city", type: "text", name: "address.route" },
  { label: "state", type: "text", name: "address.route" },
  { label: "zip code", type: "text", name: "address.postal_code" },
  { label: "npi number", type: "text", name: "npi_numbers[0]" },
  { label: "", type: "btn", btnLabel: "Confirm" },
];

const FacilitiesPage = () => {
  const dispatch = useDispatch();
  const facilities = useSelector((state: RootState) => state.demand.facilities || []);
  const facilitiesError = useSelector((state: RootState) => state.demand.error);
  const [selectedFacility, setSelectedFacility] = useState<Facility | undefined>();
  const { id } = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    if (id) {
      dispatch(getOrganizationFacilities(id));
    }
  }, [dispatch, id]);

  const handleSelect = (element: Facility) => {
    setSelectedFacility(element);
  };

  const handleConfirm = (
    facility: Facility,
    codes: { npi: string; codes: TaxonomyCodeType[] | undefined }[]
  ) => {
    if (facility.serviceable === false) {
      codes.forEach((code) => {
        if (code.codes && code.codes.length > 0) {
          dispatch(
            createFacilityTaxonomyCodes(
              facility,
              code.npi,
              code.codes.map((c: TaxonomyCodeType) => c["PROVIDER TAXONOMY CODE"])
            )
          );
        }
      });
      setSelectedFacility(undefined);
    } else {
      setSelectedFacility(undefined);
    }
  };

  return (
    <ApplicationLayout>
      <div className={styles.Container}>
        {facilitiesError && <p>Error while requesting facility</p>}
        {facilities.length > 0 && (
          <ListView
            list={facilities}
            idField="facility_id"
            view={FACILITIES_VIEW}
            handleClick={handleSelect}
          />
        )}
        {facilities.length === 0 && <p>No facilities yet</p>}
        {selectedFacility && (
          <FacilityCard
            data={selectedFacility}
            handleCancel={() => setSelectedFacility(undefined)}
            handleConfirm={handleConfirm}
          />
        )}
      </div>
    </ApplicationLayout>
  );
};

export { FacilitiesPage };
