import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Button, Typography } from "@mui/material";
import { RootState } from "../../../store";
import {
  createCertificateCredentialStatus,
  getNurseCertificates,
} from "../../../store/slices/nurses";
import { Certificate, USCertificate } from "../../../types";
import Subtable from "../../../components/Subtable";
import { CertificateCard } from "../../../components/CertificateCard";
import { CERTIFICATES_LIST } from "../../../constants/certificates";
import { generateStatusStringByKey, generateStatusStyles } from "../../../utils";

const NurseCertificatesBlock = ({ nurseId }: { nurseId: string }) => {
  const { nurseCertificates, certificatesLoading } = useSelector(
    (state: RootState) => state.nurses
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNurseCertificates(nurseId));
  }, [dispatch, nurseId]);

  const [selectedCertificate, setSelectedCertificate] = useState<USCertificate | null>(null);
  const handleShowCertificate = (certificate: USCertificate) => {
    setSelectedCertificate(certificate);
  };

  const handleCloseCertificate = () => {
    setSelectedCertificate(null);
  };

  const certificates = nurseCertificates
    ? Object.keys(nurseCertificates).map((type, index) => ({
        ...nurseCertificates[type],
        certificate_type: type,
        account_id: nurseId,
      }))
    : null;

  const tableColumns = [
    {
      title: "Certificate name",
      accessor: (certificate: USCertificate) => {
        return (
          <b>{CERTIFICATES_LIST.find((c) => c.id === certificate.certificate_type)?.label || ""}</b>
        );
      },
    },
    {
      title: "First name",
      accessor: (certificate: USCertificate) => {
        return certificate.first_name;
      },
    },
    {
      title: "Last name",
      accessor: (certificate: USCertificate) => {
        return certificate.last_name;
      },
    },
    {
      title: "Ecard code",
      accessor: (certificate: USCertificate) => {
        return certificate.ecard_code;
      },
    },

    {
      title: "Expiration date",
      accessor: (certificate: USCertificate) => {
        // <div>{dayjs(certData.renewal_date).format("LL")}</div>
        return dayjs(certificate.renewal_date).format("LL");
      },
    },
    {
      title: "Status",
      accessor: (certificate: USCertificate) => {
        const status = generateStatusStringByKey(
          certificate.current_credential_status.credential_status.credential_status_type
            .credential_status_type
        );
        return (
          <Typography variant="body1" sx={generateStatusStyles(status)}>
            {status}
          </Typography>
        );
      },
      align: "right",
    },
    {
      title: "",
      accessor: (certificate: USCertificate) => {
        return (
          <Button onClick={() => handleShowCertificate(certificate)} style={{ width: "120" }}>
            View
          </Button>
        );
      },
      align: "right",
      width: "100px",
    },
  ];

  const handleSubmit = useCallback(
    (certificate: Certificate) => {
      if (certificate.certificate_type) {
        const typeKeys = certificate.certificate_type.split("_");
        const typeKey = typeKeys.slice(1, typeKeys.length - 1).join("-");
        dispatch(
          createCertificateCredentialStatus(typeKey, certificate, () => {
            setSelectedCertificate(null);
            dispatch(getNurseCertificates(nurseId));
          })
        );
      }
    },
    [dispatch, nurseId]
  );

  return (
    <>
      <Subtable
        loading={certificatesLoading}
        data={certificates}
        title="Certificates"
        notFoundText="No certificates found"
        columns={tableColumns}
      />

      {selectedCertificate && (
        <CertificateCard
          certificate={selectedCertificate}
          handleCancel={handleCloseCertificate}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default NurseCertificatesBlock;
