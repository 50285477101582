import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { theme } from "./constants/theme";
import Routes from "./routes";

import store from "./store/store";

const appTheme = createTheme(theme);

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline>
          <SnackbarProvider maxSnack={3}>
            <Provider store={store}>
              <Routes />
            </Provider>
          </SnackbarProvider>
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
