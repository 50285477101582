import api from "./api";
import { Facility } from "../types";
import { getErrorMessage } from "../utils";

const getDemand = async () => {
  try {
    const response = await api.get("/podnik/api/v1/demand/organizations/all");
    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getNonServiceableFacilities = async (organizationId: string) => {
  try {
    const response = await api.get(
      "/podnik/api/v1/demand/facilities/notserviceable?organization_id=" + organizationId
    );
    return response.data.data.map((el: Facility) => ({ ...el, serviceable: false }));
  } catch (error) {
    return [];
  }
};

const getServiceableFacilities = async (organizationId: string) => {
  try {
    const response = await api.get(
      "/podnik/api/v1/demand/facilities/serviceable?organization_id=" + organizationId
    );
    return response.data.data.map((el: Facility) => ({ ...el, serviceable: true }));
  } catch (error) {
    return [];
  }
};

const getOrganizationFacilities = async (id: string) => {
  try {
    const nonServiceableFacilities = await getNonServiceableFacilities(id);
    const serviceableFacilities = await getServiceableFacilities(id);

    const allFacilities = nonServiceableFacilities.concat(serviceableFacilities);
    return allFacilities;
  } catch (error) {
    console.log("Error", error);
    throw new Error(getErrorMessage(error));
  }
};

const createFacilityTaxonomyCodes = async (facility: Facility, npi: string, codes: string[]) => {
  try {
    const response = await api.post("/podnik/api/v1/demand/facility/taxonomy-codes", {
      data: {
        facility_id: facility.facility_id,
        facility_npi_number_id: npi,
        taxonomy_codes: codes,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const demandService = {
  getDemand,
  getOrganizationFacilities,
  createFacilityTaxonomyCodes,
};
