import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getDemand } from "../../store/slices/demand";
import { RootState } from "../../store";
import arrowImg from "../../assets/images/etc/arrow-right.svg";
import { Organization } from "../../types";

import styles from "./organizations.module.scss";
import ApplicationLayout from "../../layout/ApplicationLayout";

const OrganizationsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organizations = useSelector((state: RootState) => state.demand.organizations);

  useEffect(() => {
    dispatch(getDemand());
  }, [dispatch]);

  const handleClickOrganization = (organization: Organization) => {
    history.push(`/organizations/${organization.organization_id}/facilities`);
  };

  return (
    <ApplicationLayout>
      <div className={styles.Container}>
        {organizations.map((organization: Organization) => {
          return (
            <div
              className={styles.ListElement}
              key={organization.organization_id}
              onClick={() => {
                handleClickOrganization(organization);
              }}
            >
              <div>{organization.organization_name}</div>
              <img src={arrowImg} alt="arrow" />
            </div>
          );
        })}
      </div>
    </ApplicationLayout>
  );
};

export { OrganizationsPage };
