import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Facility } from "../../types";
import { Autocomplete, TaxonomyCodeType } from "../Autocomplete";
import styles from "./facility-card.module.scss";
import Input from "../Input";

interface FacilityCardProps {
  data: Facility;
  handleCancel: () => void;
  handleConfirm: (
    facility: Facility,
    codes: { npi: string; codes: TaxonomyCodeType[] | undefined }[]
  ) => void;
}

const FacilityCard = ({ data, handleCancel, handleConfirm }: FacilityCardProps) => {
  const [taxonomyCodes, setTaxonomyCodes] = useState<
    { npi: string; codes: TaxonomyCodeType[] | undefined }[]
  >(
    data.npi_numbers.map((npi_number) => {
      return { npi: npi_number.facility_npi_number_id, codes: undefined };
    })
  );

  const handleChangeTaxonomyCodes = (npi: string, values: TaxonomyCodeType[]) => {
    console.log("Change", npi, values, taxonomyCodes);
    setTaxonomyCodes((state) =>
      state.map((s) => {
        if (s.npi === npi) {
          return {
            npi,
            codes: values,
          };
        }
        return s;
      })
    );
  };

  return (
    <Dialog open={true} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Facility</DialogTitle>
      <DialogContent>
        <form>
          <div>
            <Input
              variant="filled"
              label="Facility name"
              value={data.facility_name}
              margin="normal"
              fullWidth
            />
            <Input
              label="Street address"
              variant="filled"
              value={data.address.route}
              margin="normal"
              fullWidth
            />
            <Input
              label="City"
              variant="filled"
              value={data.address.administrative_area_level_1}
              margin="normal"
              fullWidth
            />
            <Input
              label="State"
              variant="filled"
              value={data.address.country}
              margin="normal"
              fullWidth
            />
            <Input
              label="Zip Code"
              variant="filled"
              value={data.address.postal_code}
              margin="normal"
              fullWidth
            />
            {data.npi_numbers.map((npi_number) => (
              <div className={styles.NPINumberSet}>
                <Input
                  label="NPI Number"
                  disabled
                  variant="filled"
                  value={npi_number.npi_number}
                  margin="normal"
                  fullWidth
                />
                <Autocomplete
                  value={taxonomyCodes?.find((c) => c.npi === npi_number.npi_number)?.codes}
                  onChange={(value) =>
                    handleChangeTaxonomyCodes(npi_number.facility_npi_number_id, value)
                  }
                />
              </div>
            ))}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() =>
            handleConfirm(
              {
                ...data,
              },
              taxonomyCodes
            )
          }
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { FacilityCard };
