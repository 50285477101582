// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

export type TaxonomyCodeType = {
  "MEDICARE PROVIDER/SUPPLIER TYPE DESCRIPTION": string;
  "MEDICARE SPECIALTY CODE": string;
  "PROVIDER TAXONOMY DESCRIPTION: TYPE CLASSIFICATION SPECIALIZATION": string;
  "PROVIDER TAXONOMY CODE": string;
};

interface AsyncAutocompleteProps {
  value: TaxonomyCodeType[] | undefined;
  onChange: (value: TaxonomyCodeType[]) => void;
}

const AsyncAutocomplete = (props: AsyncAutocompleteProps) => {
  const { value, onChange } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<TaxonomyCodeType[]>([]);
  // const styles = useStyles();

  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await axios.get(
        "https://data.cms.gov/data-api/v1/dataset/88bd5fb4-7b5c-4107-8131-23c485e00ef0/data"
      );
      const codes = await response.data;
      console.log("Codes", codes);
      if (active) {
        setOptions(codes);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: TaxonomyCodeType[]) => {
      console.log("New value", newValue);
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      multiple
      fullWidth
      id="taxonomy-codes"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option["PROVIDER TAXONOMY CODE"] === value["PROVIDER TAXONOMY CODE"]
      }
      getOptionLabel={(option) =>
        option["PROVIDER TAXONOMY CODE"] +
        " " +
        (option["PROVIDER TAXONOMY DESCRIPTION: TYPE CLASSIFICATION SPECIALIZATION"]
          ? option["PROVIDER TAXONOMY DESCRIPTION: TYPE CLASSIFICATION SPECIALIZATION"]
          : "")
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          label="Taxonomy codes"
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { AsyncAutocomplete as Autocomplete };
