import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { getCredentialStatusTypes } from "../../store/slices/nurses";
import { RootState } from "../../store";
import { Certificate } from "../../types";
import styles from "./CertificateCard.module.scss";

type CertificateCardProps = {
  certificate: Certificate;
  handleCancel: () => void;
  handleSubmit: (certificate: Certificate) => void;
};

const CertificateCard = (props: CertificateCardProps) => {
  const { certificate, handleCancel, handleSubmit } = props;
  const [viewData, setViewData] = useState<Certificate>(certificate);
  const dispatch = useDispatch();
  const { statusTypes } = useSelector((state: RootState) => state.nurses);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const statusType = statusTypes.find((s) => s.credential_status_type_key === value);
    if (statusType) {
      setViewData({
        ...viewData,
        current_credential_status: {
          credential_status: {
            credential_status_type: statusType,
          },
        },
      });
    }
  };

  useEffect(() => {
    dispatch(getCredentialStatusTypes());
  }, [dispatch]);

  return (
    <Dialog open={true} maxWidth="md" onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Certificate</DialogTitle>
      <DialogContent>
        <div className={styles.Container}>
          <div className={styles.TopBlockWrapper}>
            <div className={styles.InfoBlock}>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>id first name</div>
                <div className={styles.Value}>{viewData.first_name}</div>
              </div>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>id last name</div>
                <div className={styles.Value}>{viewData.last_name}</div>
              </div>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>ecard code</div>
                <div className={styles.Value}>{viewData.ecard_code}</div>
              </div>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>renewal date</div>
                <div className={styles.Value}>{dayjs(viewData.renewal_date).format("LL")}</div>
              </div>
            </div>
            <div className={styles.ImagesBlock}>
              {viewData.images &&
                viewData.images.map((image, index) => (
                  <div className={styles.ImageElement} key={index}>
                    <img src={image.certificate_image_url} alt="Certificate" />
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.BottomBlockWrapper}>
            <Select
              value={
                viewData.current_credential_status.credential_status.credential_status_type
                  .credential_status_type_key
              }
              onChange={handleSelect}
              style={{ width: "300px" }}
            >
              {statusTypes
                .filter(
                  (status) =>
                    status.credential_status_source.credential_status_source_key === "para"
                )
                .map((status) => (
                  <MenuItem value={status.credential_status_type_key}>
                    {status.credential_status_type}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleSubmit(viewData)} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CertificateCard };
