import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Button, Typography } from "@mui/material";
import { RootState } from "../../../store";
import { createLicenseCredentialStatus, getNurseLicenses } from "../../../store/slices/nurses";
import { USLicense } from "../../../types";
import { isDrivingLicense } from "../../../utils/typeGuards";
import { LicenseCard } from "../../../components/LicenseCard";
import Subtable from "../../../components/Subtable";
import { generateStatusStringByKey, generateStatusStyles } from "../../../utils";

const NurseLicensesBlock = ({ nurseId }: { nurseId: string }) => {
  const { nurse, nurseLicenses, licensesLoading } = useSelector((state: RootState) => state.nurses);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNurseLicenses(nurseId));
  }, [dispatch, nurseId]);

  const [selectedLicense, setSelectedLicense] = useState<USLicense | null>(null);
  const handleShowLicense = (license: USLicense) => {
    setSelectedLicense(license);
  };

  const handleCloseLicense = () => {
    setSelectedLicense(null);
  };

  const licenses: USLicense[] | null = nurseLicenses;

  const tableColumns = [
    {
      title: "License name",
      accessor: (license: USLicense) => {
        return (
          <b>
            {isDrivingLicense(license)
              ? "Driving License"
              : license.us_nursing_license_type.us_nursing_license_type}
          </b>
        );
      },
    },
    {
      title: "First name",
      accessor: (license: USLicense) => {
        return license.first_name;
      },
    },
    {
      title: "Last name",
      accessor: (license: USLicense) => {
        return license.last_name;
      },
    },
    {
      title: "License number",
      accessor: (license: USLicense) => {
        return isDrivingLicense(license)
          ? license.drivers_license_number
          : license.us_nursing_license_number;
      },
    },
    {
      title: "Issuing state",
      accessor: (license: USLicense) => {
        return license.issuing_region.region_name || license.issuing_region.iso_3166_2_code;
      },
    },
    {
      title: "Expiration date",
      accessor: (license: USLicense) => {
        console.log("Access", dayjs(license.expiration_date).format("LL"));
        return dayjs(license.expiration_date).format("LL");
      },
    },
    {
      title: "Status",
      accessor: (license: USLicense) => {
        const status = generateStatusStringByKey(
          license.current_credential_status.credential_status.credential_status_type
            .credential_status_type ||
            license.current_credential_status.credential_status.credential_status_type
              .credential_status_type_key
        );
        return (
          <Typography variant="body1" sx={generateStatusStyles(status)}>
            {status}
          </Typography>
        );
      },
      align: "right",
    },
    {
      title: "",
      accessor: (license: USLicense) => {
        return (
          <Button onClick={() => handleShowLicense(license)} style={{ width: "120" }}>
            View
          </Button>
        );
      },
      align: "right",
      width: "100px",
    },
  ];

  const handleSubmit = useCallback(
    (license: USLicense, accountId: string) => {
      dispatch(
        createLicenseCredentialStatus(license, accountId, () => {
          setSelectedLicense(null);
          dispatch(getNurseLicenses(nurseId));
        })
      );
    },
    [dispatch, nurseId]
  );

  return (
    <>
      <Subtable
        loading={licensesLoading}
        data={licenses}
        title="Licenses"
        notFoundText="No licenses found"
        columns={tableColumns}
      />

      {selectedLicense && (
        <LicenseCard
          license={selectedLicense}
          accountId={nurse?.person_id || ""}
          handleCancel={handleCloseLicense}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default NurseLicensesBlock;
