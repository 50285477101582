import { createSlice } from "@reduxjs/toolkit";
import { demandService } from "../../services/demand.service";
import { Facility, Organization } from "../../types";
import { AppDispatch } from "../store";
import { getErrorMessage } from "../../utils";

type DemandState = {
  isLoading: boolean;
  facilities: Facility[];
  organizations: Organization[];
  error: string | null;
};

export const demandSlice = createSlice({
  name: "demand",
  initialState: {
    isLoading: false,
    facilities: [],
    organizations: [],
    error: null,
  } as DemandState,
  reducers: {
    getDemandRequest: (state) => {
      state.isLoading = true;
    },
    getDemandSuccess: (state, action) => {
      state.isLoading = false;
      state.organizations = action.payload.data;
    },
    getDemandFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getOrganizationFacilitiesRequest: (state) => {
      state.isLoading = true;
    },
    getOrganizationFacilitiesSuccess: (state, action) => {
      state.isLoading = false;
      state.facilities = action.payload;
    },
    getOrganizationFacilitiesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createFacilityTaxonomyCodesRequest: (state) => {
      state.isLoading = true;
    },
    createFacilityTaxonomyCodesSuccess: (state) => {
      state.isLoading = false;
    },
    createFacilityTaxonomyCodesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
});

export const {
  getDemandRequest,
  getDemandSuccess,
  getDemandFailure,
  getOrganizationFacilitiesRequest,
  getOrganizationFacilitiesSuccess,
  getOrganizationFacilitiesFailure,
  createFacilityTaxonomyCodesRequest,
  createFacilityTaxonomyCodesSuccess,
  createFacilityTaxonomyCodesFailure,
  resetError,
} = demandSlice.actions;

export const getDemand = () => async (dispatch: AppDispatch) => {
  dispatch(getDemandRequest());
  try {
    const demand = await demandService.getDemand();
    if (demand) {
      dispatch(getDemandSuccess(demand));
    }
  } catch (error) {
    dispatch(getDemandFailure(getErrorMessage(error)));
  }
};

export const getOrganizationFacilities = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(getOrganizationFacilitiesRequest());
  try {
    const demand = await demandService.getOrganizationFacilities(id);
    if (demand) {
      dispatch(getOrganizationFacilitiesSuccess(demand));
    }
  } catch (error) {
    dispatch(getOrganizationFacilitiesFailure(getErrorMessage(error)));
  }
};

export const createFacilityTaxonomyCodes =
  (facility: Facility, npi: string, codes: string[]) => async (dispatch: AppDispatch) => {
    dispatch(createFacilityTaxonomyCodesRequest());
    try {
      const response = await demandService.createFacilityTaxonomyCodes(facility, npi, codes);
      if (response) {
        dispatch(createFacilityTaxonomyCodesSuccess());
      }
    } catch (error) {
      dispatch(createFacilityTaxonomyCodesFailure(getErrorMessage(error)));
    }
  };

export default demandSlice.reducer;
