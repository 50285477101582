import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  // createVaccinationsCredentialStatus,
  getPendingScreenings,
} from "../../../../store/slices/nurses";
import { ListView } from "../../../../components/ListView";
import { MedicalScreening } from "../../../../types";
// import { DriversLicenseCard } from './DriversLicenseCard';

const SCREENINGS_VIEW = [
  { label: "id", type: "text", name: "medical_screening_id" },
  {
    label: "screening date",
    type: "date",
    format: "M / DD / YYYY",
    name: "screening_date",
  },
  { label: "", type: "btn", btnLabel: "Validate" },
];

interface ScreeningsListProps {
  type?: {
    label: string;
    route: string;
  };
}

const ScreeningsList = ({ type }: ScreeningsListProps) => {
  // const [selectedCertificate, setSelectedCertificate] = useState<Certificate>();
  const [screenings, setScreenings] = useState<MedicalScreening[]>([]);
  const dispatch = useDispatch();
  const screeningsList = useSelector((state: RootState) => state.nurses.screenings);

  useEffect(() => {
    dispatch(getPendingScreenings());
  }, [dispatch]);

  useEffect(() => {
    if (type) {
      setScreenings(
        screeningsList.filter((v) => v.medical_screening_type.medical_screening_type === type.label)
      );
    }
  }, [screeningsList, type]);

  const handleSelect = useCallback((certificate) => {
    // setSelectedCertificate(certificate);
  }, []);

  // const handleSubmit = useCallback(
  //   (certificate: Certificate) => {
  //     dispatch(
  //       createCertificateCredentialStatus(type, certificate, () => {
  //         setSelectedCertificate(undefined);
  //         dispatch(getPendingDriversLicenses());
  //       }),
  //     );
  //   },
  //   [dispatch, type],
  // );

  return (
    <div>
      {screenings.length > 0 && (
        <ListView
          view={SCREENINGS_VIEW}
          idField="medical_screening_id"
          handleClick={handleSelect}
          list={screenings}
        />
      )}
      {screenings.length === 0 && <p>No pending screenings yet</p>}
    </div>
  );
};
export { ScreeningsList };
