import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../utils";
import { nurseService } from "../../services/nurse.service";
import {
  CredentialStatusType,
  HealthRecordStatusType,
  USDrivingLicense,
  LicenseImage,
  NurseListItem,
  Certificate,
  Vaccination,
  MedicalScreening,
  Nurse,
  ProfileImage,
  LicensesPayload,
  USLicense,
  CertificatesPayload,
} from "../../types";
import { AppDispatch } from "../store";

type NurseState = {
  isLoading: boolean;
  isPhotoLoading: boolean;
  certificatesLoading: boolean;
  licensesLoading: boolean;
  vaccinationsLoading: boolean;
  statusTypes: CredentialStatusType[];
  healthRecordStatusTypes: HealthRecordStatusType[];
  licenses: USDrivingLicense[];
  licenseImages: LicenseImage[];
  certificates: Certificate[];
  vaccinations: Vaccination[];
  screenings: MedicalScreening[];
  facilities: string[];
  nurses: NurseListItem[];
  nurse: Nurse | null;
  nursePhoto: ProfileImage | null;
  nurseCertificates: CertificatesPayload | null;
  nurseLicenses: LicensesPayload | null;
  nurseVaccinations: Vaccination[];
  error: string | null;
};

export const nurseSlice = createSlice({
  name: "nurse",
  initialState: {
    isLoading: false,
    isPhotoLoading: false,
    certificatesLoading: false,
    licensesLoading: false,
    vaccinationsLoading: false,
    statusTypes: [],
    healthRecordStatusTypes: [],
    licenses: [],
    licenseImages: [],
    certificates: [],
    vaccinations: [],
    screenings: [],
    facilities: [],
    nurses: [],
    nurse: null,
    nursePhoto: null,
    nurseCertificates: null,
    nurseLicenses: null,
    nurseVaccinations: [],
    error: null,
  } as NurseState,
  reducers: {
    getCredentialStatusTypesRequest: (state) => {
      state.isLoading = true;
    },
    getCredentialStatusTypesSuccess: (state, action) => {
      state.isLoading = false;
      state.statusTypes = action.payload;
    },
    getCredentialStatusTypesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getHealthRecordStatusTypesRequest: (state) => {
      state.isLoading = true;
    },
    getHealthRecordStatusTypesSuccess: (state, action) => {
      state.isLoading = false;
      state.healthRecordStatusTypes = action.payload;
    },
    getHealthRecordStatusTypesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNursesRequest: (state) => {
      state.isLoading = true;
    },
    getNursesSuccess: (state, action) => {
      state.isLoading = false;
      state.nurses = action.payload;
    },
    getNursesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNurseRequest: (state) => {
      state.isLoading = true;
    },
    getNurseSuccess: (state, action) => {
      state.isLoading = false;
      state.nurse = action.payload;
    },
    getNurseFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNursePhotoRequest: (state) => {
      state.isPhotoLoading = true;
    },
    getNursePhotoSuccess: (state, action) => {
      state.isPhotoLoading = false;
      state.nursePhoto = action.payload;
    },
    getNursePhotoFailure: (state, action) => {
      state.isPhotoLoading = false;
      state.error = action.payload;
    },
    getNurseCertificatesRequest: (state) => {
      state.certificatesLoading = true;
    },
    getNurseCertificatesSuccess: (state, action) => {
      state.certificatesLoading = false;
      state.nurseCertificates = action.payload;
    },
    getNurseCertificatesFailure: (state, action) => {
      state.certificatesLoading = false;
      state.error = action.payload;
    },
    getNurseVaccinationsRequest: (state) => {
      state.vaccinationsLoading = true;
    },
    getNurseVaccinationsSuccess: (state, action) => {
      state.vaccinationsLoading = false;
      state.nurseVaccinations = action.payload;
    },
    getNurseVaccinationsFailure: (state, action) => {
      state.vaccinationsLoading = false;
      state.error = action.payload;
    },
    getNurseLicensesRequest: (state) => {
      state.licensesLoading = true;
    },
    getNurseLicensesSuccess: (state, action) => {
      state.licensesLoading = false;
      state.nurseLicenses = action.payload;
    },
    getNurseLicensesFailure: (state, action) => {
      state.licensesLoading = false;
      state.error = action.payload;
    },
    getPendingLicensesRequest: (state) => {
      state.isLoading = true;
    },
    getPendingLicensesSuccess: (state, action) => {
      state.isLoading = false;
      state.licenses = action.payload;
    },
    getPendingLicensesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPendingCertificatesRequest: (state) => {
      state.isLoading = true;
    },
    getPendingCertificatesSuccess: (state, action) => {
      state.isLoading = false;
      state.certificates = action.payload;
    },
    getPendingCertificatesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getLicenseImagesSuccess: (state, action) => {
      state.isLoading = false;
      state.licenseImages = action.payload;
    },
    getPendingVaccinationsRequest: (state) => {
      state.isLoading = true;
    },
    getPendingVaccinationsSuccess: (state, action) => {
      state.isLoading = false;
      state.vaccinations = action.payload;
    },
    getPendingVaccinationsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPendingScreeningsRequest: (state) => {
      state.isLoading = true;
    },
    getPendingScreeningsSuccess: (state, action) => {
      state.isLoading = false;
      state.screenings = action.payload;
    },
    getPendingScreeningsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getCredentialStatusTypesRequest,
  getCredentialStatusTypesSuccess,
  getCredentialStatusTypesFailure,
  getHealthRecordStatusTypesRequest,
  getHealthRecordStatusTypesSuccess,
  getHealthRecordStatusTypesFailure,
  getNursesRequest,
  getNursesSuccess,
  getNursesFailure,
  getNurseRequest,
  getNurseSuccess,
  getNurseFailure,
  getNursePhotoRequest,
  getNursePhotoSuccess,
  getNursePhotoFailure,
  getNurseCertificatesRequest,
  getNurseCertificatesSuccess,
  getNurseCertificatesFailure,
  getNurseLicensesRequest,
  getNurseLicensesSuccess,
  getNurseLicensesFailure,
  getNurseVaccinationsRequest,
  getNurseVaccinationsSuccess,
  getNurseVaccinationsFailure,
  getPendingLicensesRequest,
  getPendingLicensesSuccess,
  getPendingLicensesFailure,
  getPendingCertificatesRequest,
  getPendingCertificatesSuccess,
  getPendingCertificatesFailure,
  getPendingVaccinationsRequest,
  getPendingVaccinationsSuccess,
  getPendingVaccinationsFailure,
  getPendingScreeningsRequest,
  getPendingScreeningsSuccess,
  getPendingScreeningsFailure,
  getLicenseImagesSuccess,
} = nurseSlice.actions;

export const getNurses = () => async (dispatch: AppDispatch) => {
  dispatch(getNursesRequest());
  try {
    const nurses = await nurseService.getNurses();
    if (nurses) {
      dispatch(getNursesSuccess(nurses));
    }
  } catch (error) {
    dispatch(getNursesFailure(getErrorMessage(error)));
  }
};

export const getNurse = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(getNurseRequest());
  try {
    const nurse = await nurseService.getNurse(id);
    if (nurse) {
      dispatch(getNurseSuccess(nurse));
    }
  } catch (error) {
    console.log("Err", error);
    dispatch(getNurseFailure(getErrorMessage(error)));
  }
};

export const getNursePhoto = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(getNursePhotoRequest());
  try {
    const photo = await nurseService.getNursePhoto(id);
    if (photo) {
      dispatch(getNursePhotoSuccess(photo));
    }
  } catch (error) {
    console.log("Err", error);
    dispatch(getNursePhotoFailure(getErrorMessage(error)));
  }
};

export const getNurseCertificates = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(getNurseCertificatesRequest());
  try {
    const certificates = await nurseService.getNurseCertificates(id);
    if (certificates) {
      dispatch(getNurseCertificatesSuccess(certificates));
    }
  } catch (error) {
    console.log("Err", error);
    dispatch(getNurseCertificatesFailure(getErrorMessage(error)));
  }
};

export const getNurseVaccinations = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(getNurseVaccinationsRequest());
  try {
    const certificates = await nurseService.getNurseVaccinations(id);
    if (certificates) {
      dispatch(getNurseVaccinationsSuccess(certificates));
    }
  } catch (error) {
    console.log("Err", error);
    dispatch(getNurseVaccinationsFailure(getErrorMessage(error)));
  }
};

export const getNurseLicenses = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(getNurseLicensesRequest());
  try {
    const licenses = await nurseService.getNurseLicenses(id);
    if (licenses) {
      dispatch(getNurseLicensesSuccess(licenses));
    }
  } catch (error) {
    console.log("Err", error);
    dispatch(getNurseLicensesFailure(getErrorMessage(error)));
  }
};

export const getCredentialStatusTypes = () => async (dispatch: AppDispatch) => {
  dispatch(getCredentialStatusTypesRequest());
  try {
    const statusTypes = await nurseService.getCredentialStatusTypes();
    if (statusTypes) {
      dispatch(getCredentialStatusTypesSuccess(statusTypes));
    }
  } catch (error) {
    dispatch(getCredentialStatusTypesFailure(getErrorMessage(error)));
  }
};

export const getHealthRecordStatusTypes = () => async (dispatch: AppDispatch) => {
  dispatch(getHealthRecordStatusTypesRequest());
  try {
    const statusTypes = await nurseService.getHealthRecordStatusTypes();
    if (statusTypes) {
      dispatch(getHealthRecordStatusTypesSuccess(statusTypes));
    }
  } catch (error) {
    dispatch(getHealthRecordStatusTypesFailure(getErrorMessage(error)));
  }
};

export const getPendingLicenses = (type: string) => async (dispatch: AppDispatch) => {
  dispatch(getPendingLicensesRequest());
  try {
    const licenses = await nurseService.getPendingLicenses(type);
    if (licenses) {
      dispatch(getPendingLicensesSuccess(licenses));
    }
  } catch (error) {
    dispatch(getPendingLicensesFailure(getErrorMessage(error)));
  }
};

export const createLicenseCredentialStatus =
  (license: USLicense, accountId: string, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const licenses = await nurseService.createLicenseCredentialStatus(license, accountId);
      if (licenses) {
        callback?.();
      }
    } catch (error) {
      console.log("Error while creating driver license credential status", error);
    }
  };

export const getLicenseImages =
  (license: USLicense, accountId: string) => async (dispatch: AppDispatch) => {
    try {
      const images = await nurseService.getLicenseImages(license, accountId);
      if (images) {
        dispatch(getLicenseImagesSuccess(images));
      }
    } catch (error) {
      console.log("Error while getting images", error);
    }
  };

export const getPendingCertificates = (type: string) => async (dispatch: AppDispatch) => {
  dispatch(getPendingCertificatesRequest());
  try {
    const certificates = await nurseService.getPendingCertificates(type);
    if (certificates) {
      dispatch(getPendingCertificatesSuccess(certificates));
    }
  } catch (error) {
    dispatch(getPendingCertificatesFailure(getErrorMessage(error)));
  }
};

export const getPendingVaccinations = () => async (dispatch: AppDispatch) => {
  dispatch(getPendingVaccinationsRequest());
  try {
    const vaccinations = await nurseService.getPendingVaccinations();
    if (vaccinations) {
      dispatch(getPendingVaccinationsSuccess(vaccinations));
    }
  } catch (error) {
    dispatch(getPendingVaccinationsFailure(getErrorMessage(error)));
  }
};

export const getPendingScreenings = () => async (dispatch: AppDispatch) => {
  dispatch(getPendingScreeningsRequest());
  try {
    const screenings = await nurseService.getPendingScreenings();
    if (screenings) {
      dispatch(getPendingScreeningsSuccess(screenings));
    }
  } catch (error) {
    dispatch(getPendingScreeningsFailure(getErrorMessage(error)));
  }
};

export const createCertificateCredentialStatus =
  (type: string, certificate: Certificate, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await nurseService.createCertificateCredentialStatus(type, certificate);
      if (response) {
        callback?.();
      }
    } catch (error) {
      console.log("Error while creating certificate credential status", error);
    }
  };

export const createVaccinationCredentialStatus =
  (vaccination: Vaccination, callback?: () => void) => async (dispatch: AppDispatch) => {
    try {
      const response = await nurseService.createVaccinationCredentialStatus(vaccination);
      if (response) {
        callback?.();
      }
    } catch (error) {
      console.log("Error while creating vaccination credential status", error);
    }
  };

export default nurseSlice.reducer;
