import React from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

interface Column<T> {
  title: string;
  accessor: (entry: T) => JSX.Element | string | undefined;
  align?: string;
  width?: string;
}

interface SubtableProps<T> {
  title: string;
  notFoundText: string;
  loading: boolean;
  data: T[] | null;
  columns: Column<T>[];
}

const Subtable = <T,>(props: SubtableProps<T>) => {
  const { title, notFoundText, loading, data, columns } = props;
  return (
    <Box sx={{ my: 4 }}>
      {loading && <CircularProgress />}
      {!loading && data && data.length === 0 && (
        <Typography variant="subtitle1">{notFoundText}</Typography>
      )}
      {!loading && data && data.length > 0 && (
        <>
          <Typography variant="h6">{title}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell
                    key={i}
                    component="th"
                    align={
                      columns[i].align
                        ? (columns[i].align as
                            | "right"
                            | "left"
                            | "inherit"
                            | "center"
                            | "justify"
                            | undefined)
                        : "inherit"
                    }
                  >
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((license, index) => {
                return (
                  <TableRow key={index}>
                    {columns.map((column, i) => (
                      <TableCell
                        sx={{ width: i === 0 ? "300px" : column.width ? column.width : "auto" }}
                        align={
                          columns[i].align
                            ? (columns[i].align as
                                | "right"
                                | "left"
                                | "inherit"
                                | "center"
                                | "justify"
                                | undefined)
                            : "inherit"
                        }
                      >
                        {columns[i].accessor(license)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default Subtable;
