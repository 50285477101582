import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { Navigation } from "./Navigation";

const ApplicationLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Box>
      <Navigation />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgba(230, 230, 230, 0.4)",
          minHeight: "calc( 100vh - 64px )",
          height: "calc( 100vh - 64px )",
          overflow: "scroll",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ApplicationLayout;
