import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getNurse, getNursePhoto } from "../../store/slices/nurses";
import styles from "./NursePage.module.scss";
import { RootState } from "../../store";
import ApplicationLayout from "../../layout/ApplicationLayout";
import { Box, Skeleton, TextField } from "@mui/material";
import dayjs from "dayjs";

import NurseLicensesBlock from "./components/NurseLicensesBlock";
import NurseCertificatesBlock from "./components/NurseCertificatesBlock";
import NurseVaccinationsBlock from "./components/NurseVaccinationsBlock";
import NurseBackgroundChecksBlock from "./components/NurseBackgroundChecksBlock";

const NursePage = () => {
  const { id } = useParams<Record<string, string>>();

  const { nurse, isLoading, nursePhoto } = useSelector((state: RootState) => state.nurses);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNursePhoto(id));
    dispatch(getNurse(id));
  }, [dispatch, id]);

  return (
    <ApplicationLayout>
      <Box sx={{ p: 3 }}>
        {isLoading && <Skeleton height={300} />}
        {!isLoading && nurse && (
          <Box className={styles.MainData}>
            <Box
              className={styles.Image}
              style={{
                backgroundImage: "url(" + nursePhoto?.facial_image_url + ")",
                backgroundColor: nursePhoto?.facial_image_url ? "transparent" : "cornflowerblue",
              }}
            />
            <TextField label="First name" value={nurse.first_name} variant="filled" />
            <TextField label="Last name" value={nurse.last_name} variant="filled" />
            <TextField
              label="Email"
              value={nurse.contact_info.email_address.email_address}
              variant="filled"
            />
            <TextField
              label="Phone number"
              value={nurse.contact_info.phone_number.phone_number}
              variant="filled"
            />
            <TextField
              label="Street address"
              value={nurse.contact_info.address.route}
              variant="filled"
            />
            <TextField label="City" value={nurse.contact_info.address.locality} variant="filled" />
            <TextField
              label="State"
              value={nurse.contact_info.address.administrative_area_level_1}
              variant="filled"
            />
            <TextField
              label="Zip code"
              value={nurse.contact_info.address.postal_code}
              variant="filled"
            />
            <TextField
              label="Date of Birth"
              value={dayjs(nurse.date_of_birth).format("LL")}
              variant="filled"
            />
          </Box>
        )}

        <NurseLicensesBlock nurseId={id} />
        <NurseCertificatesBlock nurseId={id} />
        <NurseVaccinationsBlock nurseId={id} />
        <NurseBackgroundChecksBlock nurseId={id} />
      </Box>
    </ApplicationLayout>
  );
};

export default NursePage;
