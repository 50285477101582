import jwtDecode from 'jwt-decode';

export const isAuthenticated = () => {
  try {
    const token = localStorage.getItem('_t');
    if (!token) {
      return false;
    }
    const decodedToken = jwtDecode(token);
    return !!decodedToken;
  } catch (error) {
    console.error(error);
    logout();
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem('_t');
};
