import { ReactComponent as supplyImg } from "../assets/images/drawer/supply.svg";
import { ReactComponent as driversImg } from "../assets/images/drawer/drivers.svg";
import { ReactComponent as blsImg } from "../assets/images/drawer/bls.svg";
import { ReactComponent as aclsImg } from "../assets/images/drawer/acls.svg";
import { ReactComponent as palsImg } from "../assets/images/drawer/pals.svg";
import { ReactComponent as tnccImg } from "../assets/images/drawer/tncc.svg";
import { ReactComponent as enpcImg } from "../assets/images/drawer/enpc.svg";
import { ReactComponent as nihssImg } from "../assets/images/drawer/nihss.svg";
import { ReactComponent as awhonnImg } from "../assets/images/drawer/awhonn.svg";
import { ReactComponent as nrpImg } from "../assets/images/drawer/nrp.svg";
import { ReactComponent as influenzaImg } from "../assets/images/drawer/influenza.svg";
import { ReactComponent as mmrImg } from "../assets/images/drawer/mmr.svg";
import { ReactComponent as varicellaImg } from "../assets/images/drawer/varicella.svg";
import { ReactComponent as tdapImg } from "../assets/images/drawer/tdap.svg";
import { ReactComponent as tbImg } from "../assets/images/drawer/tb.svg";
import { ReactComponent as nurseImg } from "../assets/images/drawer/nurse.svg";
import { ReactComponent as checkrImg } from "../assets/images/drawer/checkr.svg";
import { ReactComponent as supplylistImg } from "../assets/images/drawer/supplylist.svg";
import { ReactComponent as demandImg } from "../assets/images/drawer/demand.svg";
// import { ReactComponent as facilitiesImg } from '../assets/images/drawer/facilities.svg';
import { ReactComponent as organizationsImg } from "../assets/images/drawer/orglist.svg";

import { NURSES, ORGANIZATIONS, PENDING } from "./routes";

export type MenuItemType = {
  label: string;
  type: "primary" | "secondary";
  url: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const menuItems: MenuItemType[] = [
  {
    label: "Supply List",
    type: "primary",
    url: NURSES,
    Icon: supplylistImg,
  },
  { label: "Pending verifications", type: "primary", url: PENDING, Icon: supplyImg },
  {
    label: "Driver`s License",
    type: "secondary",
    url: PENDING + "/category/drivers",
    Icon: driversImg,
  },
  { label: "BLS", type: "secondary", url: PENDING + "/category/BLS", Icon: blsImg },
  { label: "ACLS", type: "secondary", url: PENDING + "/category/ACLS", Icon: aclsImg },
  { label: "PALS", type: "secondary", url: PENDING + "/category/PALS", Icon: palsImg },
  { label: "TNCC", type: "secondary", url: PENDING + "/category/TNCC", Icon: tnccImg },
  { label: "ENPC", type: "secondary", url: PENDING + "/category/ENPC", Icon: enpcImg },
  { label: "NIHSS", type: "secondary", url: PENDING + "/category/NIHSS", Icon: nihssImg },
  { label: "AWHONN", type: "secondary", url: PENDING + "/category/AWHONN", Icon: awhonnImg },
  { label: "NRP", type: "secondary", url: PENDING + "/category/NRP", Icon: nrpImg },
  {
    label: "Influenza",
    type: "secondary",
    url: PENDING + "/category/Influenza",
    Icon: influenzaImg,
  },
  { label: "MMR", type: "secondary", url: PENDING + "/category/MMR", Icon: mmrImg },
  {
    label: "Varicella",
    type: "secondary",
    url: PENDING + "/category/Varicella",
    Icon: varicellaImg,
  },
  { label: "TDAP", type: "secondary", url: PENDING + "/category/TDAP", Icon: tdapImg },
  { label: "TB", type: "secondary", url: PENDING + "/category/TB", Icon: tbImg },
  { label: "COVID", type: "secondary", url: PENDING + "/category/COVID", Icon: tdapImg },
  { label: "Nurse License", type: "secondary", url: PENDING + "/category/nurse", Icon: nurseImg },
  {
    label: "10-panel Drug Check",
    type: "secondary",
    url: PENDING + "/category/10panel",
    Icon: checkrImg,
  },
  {
    label: "FACIS Level 3 Check",
    type: "secondary",
    url: PENDING + "/category/facis",
    Icon: checkrImg,
  },
  { label: "Demand", type: "primary", url: ORGANIZATIONS, Icon: demandImg },
  {
    label: "Organizations List",
    type: "secondary",
    url: ORGANIZATIONS,
    Icon: organizationsImg,
  },
];
