import axios from "axios";

import { BASE_URL } from "../config";

const UNAUTHORIZED = 401;
const NOT_FOUND = 404;

const apiHost = BASE_URL;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const api = axios.create({
  baseURL: apiHost,
  headers,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (typeof error.response === "undefined") {
      alert(
        "A network error occurred. " +
          "This could be a CORS issue or a dropped internet connection. " +
          "It is not possible for us to know."
      );
      localStorage.removeItem("_t");
      localStorage.removeItem("_i");
      localStorage.removeItem("_o");
      localStorage.removeItem("_p");
      window.location.pathname = "/";
    }
    const { status } = error.response;

    if (status === NOT_FOUND) {
      return Promise.resolve({ data: { data: [] } });
    }
    console.log("Status");
    if (status === UNAUTHORIZED) {
      localStorage.removeItem("_t");
      localStorage.removeItem("_i");
      localStorage.removeItem("_o");
      localStorage.removeItem("_p");
      window.location.pathname = "/";
    }
    return Promise.reject(error);
  }
);

if (localStorage.getItem("_t") !== null) {
  api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("_t")}`;
}
export default api;
