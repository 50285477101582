import { colors } from "../constants/theme";

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const generateId = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const generateStatusStringByKey = (status: string | undefined) => {
  switch (status) {
    case "Pending Verification":
    case "pending_verification":
    case "pending":
      return "Pending Verification";
    case "Valid Credential":
    case "valid_credential":
    case "valid_health_record":
    case "pass":
      return "Valid";
    case "invalid_credential":
    case "fail":
      return "Invalid";
    default:
      return status;
  }
};

export const generateStatusStyles = (status: string | undefined) => {
  switch (status) {
    case "Pending Verification":
      return {
        color: colors.warning,
      };
    case "Valid":
      return {
        color: colors.success,
      };
    case "Invalid":
      return {
        color: colors.alert,
      };
    default:
      return {};
  }
};

export const remCalc = (px: number): string => `${Math.round(px / 16)}rem`;
