export const VACCINATIONS_LIST = [
  {
    label: "Influenza",
    route: "Influenza",
  },
  {
    label: "MMR (measles mumps rubella)",
    route: "MMR",
  },
  {
    label: "Varicella",
    route: "Varicella",
  },
  {
    label: "TDAP (Tetanus Diphtheria Petusis)",
    route: "TDAP",
  },
  {
    label: "Hepatitis B",
    route: "HB",
  },
  {
    label: "COVID",
    route: "COVID",
  },
];
