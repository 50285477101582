import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  createCertificateCredentialStatus,
  getPendingCertificates,
} from "../../../../store/slices/nurses";
import { ListView } from "../../../../components/ListView";
import { Certificate } from "../../../../types";
import { CertificateCard } from "../../../../components/CertificateCard";

const CERTIFICATES_VIEW = [
  { label: "id first name", type: "text", name: "first_name" },
  { label: "id last name", type: "text", name: "last_name" },
  { label: "ecard code", type: "text", name: "ecard_code" },
  {
    label: "expiration date",
    type: "date",
    format: "M / DD / YYYY",
    name: "renewal_date",
  },
  { label: "", type: "btn", btnLabel: "Validate" },
];

interface CertificatesListProps {
  type: string;
}

const CertificatesList = ({ type }: CertificatesListProps) => {
  const [selectedCertificate, setSelectedCertificate] = useState<Certificate>();
  const dispatch = useDispatch();
  const certificates = useSelector((state: RootState) => state.nurses.certificates);

  useEffect(() => {
    dispatch(getPendingCertificates(type));
  }, [dispatch, type]);

  const handleSelect = useCallback((certificate) => {
    console.log("Handle select", certificate);
    setSelectedCertificate(certificate);
  }, []);

  const handleSubmit = useCallback(
    (certificate: Certificate) => {
      dispatch(
        createCertificateCredentialStatus(type, certificate, () => {
          setSelectedCertificate(undefined);
          dispatch(getPendingCertificates(type));
        })
      );
    },
    [dispatch, type]
  );

  return (
    <div>
      {certificates.length > 0 && (
        <ListView
          view={CERTIFICATES_VIEW}
          idField="credential_id"
          handleClick={handleSelect}
          list={certificates}
        />
      )}
      {certificates.length === 0 && <p>No pending certificates yet</p>}
      {selectedCertificate && (
        <CertificateCard
          certificate={selectedCertificate}
          handleCancel={() => setSelectedCertificate(undefined)}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
export { CertificatesList };
