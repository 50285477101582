import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { getHealthRecordStatusTypes } from "../../store/slices/nurses";
import { RootState } from "../../store";
import { Vaccination } from "../../types";
import styles from "./VaccinationCard.module.scss";

type VaccinationCardProps = {
  vaccination: Vaccination;
  handleCancel: () => void;
  handleSubmit: (vaccination: Vaccination) => void;
};

const VaccinationCard = (props: VaccinationCardProps) => {
  const { vaccination, handleCancel, handleSubmit } = props;
  const [viewData, setViewData] = useState<Vaccination>(vaccination);
  const dispatch = useDispatch();
  const { healthRecordStatusTypes } = useSelector((state: RootState) => state.nurses);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const statusType = healthRecordStatusTypes.find(
      (s) => s.health_record_status_type_key === value
    );
    if (statusType) {
      setViewData((s: Vaccination) => ({
        ...s,
        current_health_record_status: {
          health_record_status: {
            health_record_status_type: {
              health_record_status_source: {
                health_record_status_source:
                  statusType?.health_record_status_source.health_record_status_source,
              },
              health_record_status_type_id: statusType?.health_record_status_type_id,
              health_record_status_type_key: statusType?.health_record_status_type_key,
            },
          },
        },
      }));
    }
  };

  useEffect(() => {
    dispatch(getHealthRecordStatusTypes());
  }, [dispatch]);

  return (
    <Dialog open={true} maxWidth="md" onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Vaccination</DialogTitle>
      <DialogContent>
        <div className={styles.Container}>
          <div className={styles.TopBlockWrapper}>
            <div className={styles.InfoBlock}>
              <div className={styles.InfoElement}>
                <div className={styles.Label}>renewal date</div>
                <div className={styles.Value}>{dayjs(viewData.vaccination_date).format("LL")}</div>
              </div>
            </div>
            <div className={styles.ImagesBlock}>
              {viewData.images &&
                viewData.images.map((image, index) => (
                  <div className={styles.ImageElement}>
                    <img src={image.health_record_image_url} alt="Vaccination" />
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.BottomBlockWrapper}>
            <Select
              value={
                viewData.current_health_record_status.health_record_status.health_record_status_type
                  .health_record_status_type_key
              }
              onChange={handleSelect}
              style={{ width: "300px" }}
            >
              {healthRecordStatusTypes
                .filter(
                  (status) =>
                    status.health_record_status_source.health_record_status_source_key === "para"
                )
                .map((status) => (
                  <MenuItem value={status.health_record_status_type_key}>
                    {status.health_record_status_type}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleSubmit(viewData)} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { VaccinationCard };
