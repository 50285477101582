import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { BackgroundCheckStatusType, Facis3BackgroundScreening } from "../../types";
import styles from "./BackgroundCheckCard.module.scss";
import { nurseService } from "../../services/nurse.service";

type BackgroundCheckCardProps = {
  backgroundCheck?: Facis3BackgroundScreening;
  accountId: string;
  handleCancel: () => void;
  handleSubmit?: (backgroundCheck: Facis3BackgroundScreening, accountId: string) => void;
  handleCreate?: (type: string, expiryDate: string) => void;
};

const BackgroundCheckCard = (props: BackgroundCheckCardProps) => {
  const { backgroundCheck, accountId, handleCancel, handleSubmit, handleCreate } = props;
  const [statusTypes, setStatusTypes] = useState<BackgroundCheckStatusType[]>([]);
  const [internalBackgroundCheck, setInternalBackgroundCheck] = useState<
    Facis3BackgroundScreening | undefined
  >(backgroundCheck);
  const dispatch = useDispatch();
  console.log("License is", backgroundCheck);
  const [selectedType, setSelectedType] = useState("facis3");
  const [expiryDate, setExpiryDate] = useState("");

  const handleSelectBackgroundCheckType = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedType(value);
  };

  const handleSelectBackgroundCheckTypeDate = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value;
    console.log("Value", value);
    setExpiryDate(value);
  };

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const statusType = statusTypes.find((s) => s.background_check_status_type_key === value);
    if (statusType && internalBackgroundCheck) {
      setInternalBackgroundCheck({
        ...internalBackgroundCheck,
        current_background_check_status: {
          background_check_status: {
            background_check_status_type: statusType,
          },
        },
      });
    }
  };

  useEffect(() => {
    nurseService.getBackgroundChecksStatusTypes().then((statusTypes) => {
      console.log("Status types", statusTypes);
      setStatusTypes(statusTypes);
    });
  }, [dispatch, backgroundCheck, accountId]);

  return (
    <Dialog open={true} maxWidth="md" onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Background Check</DialogTitle>
      <DialogContent>
        {internalBackgroundCheck ? (
          <div className={styles.Container}>
            <div className={styles.TopBlockWrapper}>
              <div className={styles.InfoBlock}>
                <div className={styles.InfoElement}>
                  <div className={styles.Label}>account id</div>
                  <div className={styles.Value}>{internalBackgroundCheck.account_id}</div>
                </div>
                <div className={styles.InfoElement}>
                  <div className={styles.Label}>expiration date</div>
                  <div className={styles.Value}>
                    {dayjs(internalBackgroundCheck.expiry_at).format("LL")}
                  </div>
                </div>
              </div>
              {internalBackgroundCheck.files && internalBackgroundCheck.files.length > 0 && (
                <div className={styles.ImagesBlock}>
                  {internalBackgroundCheck.files.map((file) => (
                    <div className={styles.ImageElement}>
                      <img
                        src={file.background_check_file_url}
                        alt={file.background_check_file_uuid}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.BottomBlockWrapper}>
              <Select
                value={
                  internalBackgroundCheck.current_background_check_status.background_check_status
                    .background_check_status_type.background_check_status_type_key
                }
                onChange={handleSelect}
                style={{ width: "300px" }}
              >
                {statusTypes
                  .filter(
                    (status) =>
                      status.background_check_status_source.background_check_status_source_key ===
                      "checkr"
                  )
                  .map((status) => (
                    <MenuItem value={status.background_check_status_type_key}>
                      {status.background_check_status_type}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
        ) : (
          <Stack gap={2}>
            <Select
              value={selectedType}
              onChange={handleSelectBackgroundCheckType}
              style={{ width: "300px" }}
            >
              <MenuItem value="facis3">Facis3</MenuItem>
              <MenuItem value="tenpanel">TenPanel</MenuItem>
            </Select>
            <TextField
              type="date"
              name="start_date"
              // label="Expiry date"
              value={expiryDate}
              onChange={handleSelectBackgroundCheckTypeDate}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() =>
            internalBackgroundCheck
              ? handleSubmit?.(internalBackgroundCheck, accountId)
              : handleCreate?.(selectedType, expiryDate)
          }
          color="primary"
        >
          {internalBackgroundCheck ? "Confirm" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { BackgroundCheckCard };
