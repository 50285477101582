import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { LicensesList } from "./lists/LicensesList";
import { CertificatesList } from "./lists/CertificatesList";
import { VaccinationsList } from "./lists/VaccinationsList";
import { ScreeningsList } from "./lists/ScreeningsList";
import { BackgroundChecksList } from "./lists/BackgroundChecksList";
import ApplicationLayout from "../../layout/ApplicationLayout";
import { Box } from "@mui/material";
import { CERTIFICATES_LIST } from "../../constants/certificates";
import { LICENSES_LIST } from "../../constants/licenses";
import { VACCINATIONS_LIST } from "../../constants/vaccinations";
import { SCREENINGS_LIST } from "../../constants/screenings";
import { BACKGROUND_CHECKS_LIST } from "../../constants/backgroundChecks";

const PendingCategoryPage = () => {
  const { category } = useParams<Record<string, string>>();
  console.log("Category", category);

  const pageComponent = useMemo(() => {
    let pageComponent = null;
    if (LICENSES_LIST.map((c) => c.route).indexOf(category) !== -1) {
      pageComponent = <LicensesList type={category} />;
    } else if (CERTIFICATES_LIST.map((c) => c.route).indexOf(category) !== -1) {
      pageComponent = <CertificatesList type={category} />;
    } else if (VACCINATIONS_LIST.map((c) => c.route).indexOf(category) !== -1) {
      pageComponent = (
        <VaccinationsList type={VACCINATIONS_LIST.find((v) => v.route === category)} />
      );
    } else if (SCREENINGS_LIST.map((c) => c.route).indexOf(category) !== -1) {
      pageComponent = <ScreeningsList type={SCREENINGS_LIST.find((v) => v.route === category)} />;
    } else if (BACKGROUND_CHECKS_LIST.map((c) => c.route).indexOf(category) !== -1) {
      pageComponent = (
        <BackgroundChecksList
          type={
            BACKGROUND_CHECKS_LIST[BACKGROUND_CHECKS_LIST.map((c) => c.route).indexOf(category)]
          }
        />
      );
    }
    return pageComponent;
  }, [category]);

  return (
    <ApplicationLayout>
      <Box sx={{ p: 2 }}>{pageComponent}</Box>
    </ApplicationLayout>
  );
};

export default PendingCategoryPage;
