export const BACKGROUND_CHECKS_LIST = [
  {
    label: "10-panel Drug Check",
    route: "10panel",
  },
  {
    label: "FACIS Level 3 Check",
    route: "facis",
  },
];
