import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { getPendingLicenses, createLicenseCredentialStatus } from "../../../../store/slices/nurses";
import { ListView } from "../../../../components/ListView";
import { USLicense } from "../../../../types";
import { LicenseCard } from "../../../../components/LicenseCard";
import { isDrivingLicense } from "../../../../utils/typeGuards";
import { Box, CircularProgress } from "@mui/material";

const LICENSES_VIEW = [
  { label: "id first name", type: "text", name: "first_name" },
  { label: "id last name", type: "text", name: "last_name" },
  { label: "dl number", type: "text", name: "drivers_license_number" },
  { label: "issuing state", type: "text", name: "issuing_region.region_name" },
  {
    label: "expiration date",
    type: "date",
    format: "M / DD / YYYY",
    name: "expiration_date",
  },
  { label: "", type: "btn", btnLabel: "Validate" },
];

interface LicensesListProps {
  type: string;
}

const LicensesList = ({ type }: LicensesListProps) => {
  const [selectedLicense, setSelectedLicense] = useState<USLicense>();
  const dispatch = useDispatch();
  const { licenses, isLoading } = useSelector((state: RootState) => state.nurses);

  useEffect(() => {
    dispatch(getPendingLicenses(type));
  }, [dispatch, type]);

  const handleSelect = useCallback((driversLicense) => {
    setSelectedLicense(driversLicense);
  }, []);

  const handleSubmit = useCallback(
    (license: USLicense, accountId: string) => {
      dispatch(
        createLicenseCredentialStatus(license, accountId, () => {
          setSelectedLicense(undefined);
          dispatch(getPendingLicenses(type));
        })
      );
    },
    [dispatch, type]
  );

  return (
    <div>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && licenses.length === 0 && <p>No pending licenses yet</p>}
      {!isLoading && licenses.length > 0 && (
        <ListView
          view={
            type === "drivers"
              ? LICENSES_VIEW
              : LICENSES_VIEW.map((field, index) => {
                  if (index === 2) {
                    return { label: "nl number", type: "text", name: "us_nursing_license_number" };
                  }
                  return field;
                })
          }
          idField="credential_id"
          handleClick={handleSelect}
          list={licenses}
        />
      )}
      {selectedLicense && (
        <LicenseCard
          license={selectedLicense}
          accountId={
            isDrivingLicense(selectedLicense)
              ? selectedLicense.account_id
              : selectedLicense.us_nursing_license_id
          }
          handleCancel={() => setSelectedLicense(undefined)}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
export { LicensesList };
