import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { Facis3BackgroundScreening } from "../../../types";
import Subtable from "../../../components/Subtable";
import { nurseService } from "../../../services/nurse.service";
import { BackgroundCheckCard } from "../../../components/BackgroundCheckCard";
import { generateStatusStringByKey, generateStatusStyles } from "../../../utils";

const NurseBackgroundChecksBlock = ({ nurseId }: { nurseId: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [backgroundChecks, setBackgroundChecks] = useState<Facis3BackgroundScreening[]>([]);

  const loadBackgroundChecks = useCallback(async () => {
    setIsLoading(true);
    const tenPanelDrugChecks = await nurseService.getNurse10PanelDrugChecks(nurseId);
    const facis3BackgroundScreenings = await nurseService.getNurseFacis3BackgroundScreenings(
      nurseId
    );
    setIsLoading(false);
    setBackgroundChecks([
      ...facis3BackgroundScreenings.map((s) => ({
        ...s,
        name: "Facis3 Background Screening",
        type: "facis3",
      })),
      ...tenPanelDrugChecks.map((s) => ({ ...s, name: "10Panel Drug Check", type: "tenpanel" })),
    ]);
  }, [nurseId]);

  useEffect(() => {
    loadBackgroundChecks();
  }, [loadBackgroundChecks]);

  const [selectedBackgroundCheck, setSelectedBackgroundCheck] =
    useState<Facis3BackgroundScreening | null>(null);
  const handleShowBackgroundCheck = (backgroundCheck: Facis3BackgroundScreening) => {
    setSelectedBackgroundCheck(backgroundCheck);
  };

  const handleCloseBackgroundCheck = () => {
    setSelectedBackgroundCheck(null);
  };

  const tableColumns = [
    {
      title: "Name",
      accessor: (backgroundCheck: Facis3BackgroundScreening) => {
        return <b>{backgroundCheck.name}</b>;
      },
    },
    {
      title: "Expiration date",
      accessor: (backgroundCheck: Facis3BackgroundScreening) => {
        // <div>{dayjs(certData.renewal_date).format("LL")}</div>
        return dayjs(backgroundCheck.expiry_at).format("LL");
      },
    },
    {
      title: "Status",
      accessor: (backgroundCheck: Facis3BackgroundScreening) => {
        const status = generateStatusStringByKey(
          backgroundCheck.current_background_check_status.background_check_status
            .background_check_status_type.background_check_status_type_key
        );
        return (
          <Typography variant="body1" sx={generateStatusStyles(status)}>
            {status}
          </Typography>
        );
      },
      align: "right",
    },
    {
      title: "",
      accessor: (backgroundCheck: Facis3BackgroundScreening) => {
        return (
          <Button
            onClick={() => handleShowBackgroundCheck(backgroundCheck)}
            style={{ width: "120" }}
          >
            View
          </Button>
        );
      },
      align: "right",
      width: "100px",
    },
  ];

  const [showNewBackgroundCheckForm, setShowNewBackgroundCheckForm] = useState(false);

  const showBackgroundCheckForm = () => {
    setShowNewBackgroundCheckForm(true);
  };

  const hideBackgroundCheckForm = () => {
    setShowNewBackgroundCheckForm(false);
  };

  const handleAddBackroundCheck = useCallback(
    async (type: string, expiryDate: string) => {
      if (type === "facis3") {
        await nurseService.createNurseFacis3BackgroundScreening(nurseId, expiryDate + "T23:59:59Z");
      } else if (type === "tenpanel") {
        await nurseService.createNurse10PanelDrugCheck(nurseId, expiryDate + "T23:59:59Z");
      }
      hideBackgroundCheckForm();
      loadBackgroundChecks();
    },
    [nurseId, loadBackgroundChecks]
  );

  const handleSubmitBackgroundCheck = (
    backgroundCheck: Facis3BackgroundScreening,
    accountId: string
  ) => {
    if (backgroundCheck.type) {
      nurseService.createBackgroundCheckStatus(backgroundCheck.type, backgroundCheck).then(() => {
        setSelectedBackgroundCheck(null);
        loadBackgroundChecks();
      });
    }
  };

  return (
    <>
      {backgroundChecks.length > 0 && (
        <Subtable
          loading={isLoading}
          data={backgroundChecks}
          title="Background checks"
          notFoundText="No background checks found"
          columns={tableColumns}
        />
      )}
      {backgroundChecks.length === 0 ? (
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={showBackgroundCheckForm}>
              Add background check
            </Button>
          }
        >
          <AlertTitle>No background checks</AlertTitle>
          Until checking is complete, nurse will not be matched to any job
        </Alert>
      ) : (
        <Button onClick={showBackgroundCheckForm}>Add background check</Button>
      )}

      {showNewBackgroundCheckForm && (
        <BackgroundCheckCard
          accountId={nurseId}
          handleCancel={() => hideBackgroundCheckForm()}
          handleCreate={handleAddBackroundCheck}
        />
      )}

      {selectedBackgroundCheck && (
        <BackgroundCheckCard
          backgroundCheck={selectedBackgroundCheck}
          accountId={nurseId}
          handleCancel={() => handleCloseBackgroundCheck()}
          handleSubmit={handleSubmitBackgroundCheck}
        />
      )}
    </>
  );
};

export default NurseBackgroundChecksBlock;
