import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { LOGIN, NURSES, PENDING, ORGANIZATIONS } from "./constants/routes";

import LoginPage from "./pages/Login";

import PendingPage from "./pages/Pending";
import PendingCategoryPage from "./pages/PendingCategory";

import NursesPage from "./pages/Nurses";
import NursePage from "./pages/Nurse";

import { FacilitiesPage } from "./pages/facilities";

import { OrganizationsPage } from "./pages/organizations";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path={LOGIN}>
          <LoginPage />
        </Route>

        <PrivateRoute exact path={NURSES}>
          <NursesPage />
        </PrivateRoute>
        <PrivateRoute path={NURSES + "/:id"}>
          <NursePage />
        </PrivateRoute>
        <PrivateRoute exact path={PENDING}>
          <PendingPage />
        </PrivateRoute>
        <PrivateRoute path={PENDING + "/category/:category"}>
          <PendingCategoryPage />
        </PrivateRoute>
        <PrivateRoute exact path={ORGANIZATIONS}>
          <OrganizationsPage />
        </PrivateRoute>
        <PrivateRoute path={ORGANIZATIONS + "/:id/facilities"}>
          <FacilitiesPage />
        </PrivateRoute>
        <Redirect to={NURSES} />
      </Switch>
    </Router>
  );
};

export default Routes;
