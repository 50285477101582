import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  createVaccinationCredentialStatus,
  getPendingVaccinations,
} from "../../../../store/slices/nurses";
import { ListView } from "../../../../components/ListView";
import { Vaccination } from "../../../../types";
import { VaccinationCard } from "../../../../components/VaccinationCard";
import { Box, CircularProgress } from "@mui/material";

const VACCINATIONS_VIEW = [
  { label: "id", type: "text", name: "vaccination_id" },
  {
    label: "vaccination date",
    type: "date",
    format: "M / DD / YYYY",
    name: "vaccination_date",
  },
  { label: "", type: "btn", btnLabel: "Validate" },
];

interface VaccinationsListProps {
  type?: {
    label: string;
    route: string;
  };
}

const VaccinationsList = ({ type }: VaccinationsListProps) => {
  const [selectedVaccination, setSelectedVaccination] = useState<Vaccination>();
  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);
  const dispatch = useDispatch();
  const vaccinationsList = useSelector((state: RootState) => state.nurses.vaccinations);
  const isLoading = useSelector((state: RootState) => state.nurses.isLoading);

  useEffect(() => {
    dispatch(getPendingVaccinations());
  }, [dispatch]);

  useEffect(() => {
    if (type) {
      setVaccinations(vaccinationsList.filter((v) => v.vaccine.vaccine === type.label));
    }
  }, [vaccinationsList, type]);

  const handleSelect = useCallback((vaccination) => {
    setSelectedVaccination(vaccination);
  }, []);

  const handleSubmit = useCallback(
    (vaccination: Vaccination) => {
      dispatch(
        createVaccinationCredentialStatus(vaccination, () => {
          setSelectedVaccination(undefined);
          dispatch(getPendingVaccinations());
        })
      );
    },
    [dispatch]
  );

  return (
    <div>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && vaccinations.length > 0 && (
        <ListView
          view={VACCINATIONS_VIEW}
          idField="vaccination_id"
          handleClick={handleSelect}
          list={vaccinations}
        />
      )}
      {!isLoading && vaccinations.length === 0 && <p>No pending vaccinations yet</p>}
      {selectedVaccination && (
        <VaccinationCard
          vaccination={selectedVaccination}
          handleCancel={() => setSelectedVaccination(undefined)}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
export { VaccinationsList };
