import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import { NurseListItem } from "../../types";

const ExportToExcel = ({ users }: { users: NurseListItem[] }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (users: NurseListItem[]) => {
    const ws = XLSX.utils.json_to_sheet(
      users.map((u) => ({
        ...u,
        emrs: JSON.stringify(u.emrs),
        employments: JSON.stringify(u.employments),
        address: JSON.stringify(u.address),
      }))
    );
    console.log("WS", ws, users);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "para-crm-export" + fileExtension);
  };

  return (
    <Button
      style={{ width: "100px", marginBottom: "20px", marginLeft: "auto" }}
      variant="outlined"
      size="small"
      onClick={(e) => exportToCSV(users)}
    >
      Export
    </Button>
  );
};

export default ExportToExcel;
