import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";
import { getNurses } from "../../store/slices/nurses";
import { RootState } from "../../store";
import ExportToExcel from "../../components/ExportToExcel";
import ApplicationLayout from "../../layout/ApplicationLayout";
import { NURSES } from "../../constants/routes";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NurseListItem } from "../../types";

const NursesPage = () => {
  const dispatch = useDispatch();
  const { nurses, isLoading } = useSelector((state: RootState) => state.nurses);
  const history = useHistory();

  const handleClick = (id: string) => {
    history.push(NURSES + "/" + id);
  };

  useEffect(() => {
    dispatch(getNurses());
  }, [dispatch]);

  const getFlagEmoji = (countryCode: string) => {
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
  };

  const formatNursePhoneNumber = (nurse: NurseListItem) => {
    const phoneNumber = `+${nurse.country_code}${nurse.phone_number}`;
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    if (parsedPhoneNumber?.country) {
      const flag = getFlagEmoji(parsedPhoneNumber?.country);
      return `${flag} ${phoneNumber}`;
    }
    return phoneNumber;
  };

  return (
    <ApplicationLayout>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex" }}>
          <ExportToExcel users={nurses} />
        </Box>
        <TableContainer sx={{ maxHeight: "calc(100vh - 160px)" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell component="th">First name</TableCell>
                <TableCell component="th">Last name</TableCell>
                <TableCell component="th">Phone</TableCell>
                <TableCell component="th">Email</TableCell>
                <TableCell component="th">Region name</TableCell>
                <TableCell component="th">
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>Employments</Box>
                    {!isLoading && nurses && (
                      <Box sx={{ color: "#a9b3be", textTransform: "uppercase" }}>
                        Total: {nurses.length}
                      </Box>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {!isLoading &&
                nurses.map((nurse, index) => {
                  const formattedPhoneNumber = formatNursePhoneNumber(nurse);
                  return (
                    <TableRow
                      key={index}
                      onClick={() => handleClick(nurse.person_id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{nurse.first_name}</TableCell>
                      <TableCell>{nurse.last_name}</TableCell>
                      <TableCell>{formattedPhoneNumber}</TableCell>
                      <TableCell>{nurse.email_address}</TableCell>
                      <TableCell>{nurse.region_name}</TableCell>
                      <TableCell>
                        {nurse.employments ? (
                          <p>
                            <b>{nurse.employments.length}</b> departments,{" "}
                            <b>
                              {nurse.employments.reduce(
                                (acc, val) => (acc += val.employment_duration),
                                0
                              )}
                            </b>{" "}
                            hours total
                          </p>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ApplicationLayout>
  );
};

export default NursesPage;
