import { getErrorMessage } from "../utils";
import api from "./api";

const login = async (email: string, password: string) => {
  try {
    const response = await api.post(`/searem/api/v1/authentication/authenticate`, {
      data: { email_address: email, password },
    });
    localStorage.setItem("_t", response.data.data.credentials.jwt);
    return response.data.data.credentials.jwt;
  } catch (error) {
    console.error("Err", error);
    throw new Error(getErrorMessage(error));
  }
};

export const authService = {
  login,
};
