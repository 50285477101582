import React, { useCallback, useEffect, useState } from "react";
import { Facis3BackgroundScreening } from "../../../../types";
import { nurseService } from "../../../../services/nurse.service";
import { Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { BackgroundCheckCard } from "../../../../components/BackgroundCheckCard";

interface BackgroundChecksListProps {
  type: {
    label: string;
    route: string;
  };
}

const BackgroundChecksList = ({ type }: BackgroundChecksListProps) => {
  const [elements, setElements] = useState<Facis3BackgroundScreening[]>([]);
  const [selectedElement, setSelectedElement] = useState<Facis3BackgroundScreening>();

  const loadElements = useCallback(() => {
    if (type.route === "facis") {
      nurseService.getPendingFacis3BackgroundScreenings().then((elements) => {
        setElements(elements);
      });
    } else if (type.route === "10panel") {
      nurseService.getPending10PanelDrugChecks().then((elements) => {
        setElements(elements);
      });
    }
  }, [type]);

  useEffect(() => {
    loadElements();
  }, [loadElements]);

  const handleSelect = useCallback((element: Facis3BackgroundScreening) => {
    setSelectedElement(element);
  }, []);

  const handleSubmit = (element: Facis3BackgroundScreening, accountId: string) => {
    nurseService.createBackgroundCheckStatus("facis3", element).then(() => {
      setSelectedElement(undefined);
      loadElements();
    });
  };

  return (
    <div>
      {elements.length > 0 && (
        <Stack spacing={2}>
          {elements.map((element) => (
            <Card key={element.facis3_background_screening_id}>
              <CardContent>
                <Typography variant="subtitle1">Facis3 Background Screening</Typography>
                <Typography variant="body1">Account {element.account_id}</Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => handleSelect(element)}>View</Button>
              </CardActions>
            </Card>
          ))}
        </Stack>
      )}
      {elements.length === 0 && <p>No pending background checks yet</p>}
      {selectedElement && (
        <BackgroundCheckCard
          backgroundCheck={selectedElement}
          accountId={selectedElement.account_id}
          handleCancel={() => setSelectedElement(undefined)}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
export { BackgroundChecksList };
