import api from "./api";
import {
  APIResponse,
  Certificate,
  USDrivingLicense,
  USLicense,
  Vaccination,
  NurseListItem,
  Facis3BackgroundScreening,
} from "../types";
import { getErrorMessage } from "../utils";
import { isDrivingLicense } from "../utils/typeGuards";

const getNurses = async () => {
  try {
    const response = await api.get<APIResponse<NurseListItem[]>>("/tapatybe/api/v1/crm/supplies");
    return response.data.data.sort((a, b) => {
      if (a.first_name > b.first_name) {
        return 1;
      }
      return -1;
    });
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getNurse = async (id: string) => {
  try {
    const response = await api.get(`/tapatybe/api/v1/account/supply/person?person_id=${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getNursePhoto = async (id: string) => {
  try {
    const response = await api.get(`/tapatybe/api/v1/account/supply/profile/image?person_id=${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getNurseCertificates = async (id: string) => {
  try {
    const response = await api.get(
      `/tapatybe/api/v1/account/credentials/certificates?account_id=${id}`
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getNurseLicenses = async (id: string) => {
  try {
    const response = await api.get(
      `/tapatybe/api/v1/account/credentials/licenses?account_id=${id}`
    );
    const responseNursingLicenses = await api.get(
      `/tapatybe/api/v1/account/credentials/licenses/us/nursing-licenses?account_id=${id}`
    );
    const allLicenses = Object.keys(response.data.data)
      .filter((key) => key !== "us_nursing_license")
      .map((licenseType, index) => response.data.data[licenseType]);
    return allLicenses.concat(responseNursingLicenses.data.data);
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getNurseVaccinations = async (id: string) => {
  try {
    const response = await api.get(
      `/tapatybe/api/v1/account/health-records/vaccinations?account_id=${id}`
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getCredentialStatusTypes = async () => {
  try {
    const response = await api.get("/tapatybe/api/v1/account/credentials/credential-status-types");
    return response.data.data.credential_status_types;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getHealthRecordStatusTypes = async () => {
  try {
    const response = await api.get(
      "/tapatybe/api/v1/account/health-records/health-record-status-types"
    );
    return response.data.data.health_record_status_types;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getBackgroundChecksStatusTypes = async () => {
  try {
    const response = await api.get(
      "/tapatybe/api/v1/account/background-checks/background-check-status-types"
    );
    return response.data.data.background_check_status_types;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getPendingLicenses = async (type: string) => {
  const t = type === "drivers" ? "us-driving-licenses" : "us-nursing-licenses";
  try {
    const response = await api.get<APIResponse<USDrivingLicense[]>>(
      `/tapatybe/api/v1/crm/credentials/${t}?credential_status_source_key=para&credential_status_type_key=pending_verification`
    );
    return response.data.data.sort((a, b) => {
      if (a.first_name > b.first_name) {
        return 1;
      }
      return -1;
    });
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const createLicenseCredentialStatus = async (license: USLicense, account_id: string) => {
  const t = isDrivingLicense(license) ? "us-drivers-license" : "us-nursing-license";
  try {
    const response = await api.post(`/tapatybe/api/v1/crm/credentials/${t}/create-status`, {
      data: {
        account_id,
        credential_id: license.credential_id,
        credential_status_type_id:
          license.current_credential_status.credential_status.credential_status_type
            .credential_status_type_id,
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getLicenseImages = async (license: USLicense, accountId: string) => {
  try {
    const t = isDrivingLicense(license) ? "drivers-license" : "nursing-license";
    const url = isDrivingLicense(license)
      ? "&us_drivers_license_id=" + license.us_drivers_license_id
      : "&us_nursing_license_id=" + license.us_nursing_license_id;
    const response = await api.get(
      `/tapatybe/api/v1/account/credentials/licenses/us/${t}/images?account_id=${accountId}${url}`
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getPendingCertificates = async (type: string) => {
  try {
    const response = await api.get<APIResponse<Certificate[]>>(
      `/tapatybe/api/v1/crm/credentials/us-${type.toLowerCase()}-certificates?credential_status_source_key=para&credential_status_type_key=pending_verification`
    );
    return response.data.data.sort((a, b) => {
      if (a.first_name > b.first_name) {
        return 1;
      }
      return -1;
    });
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const createCertificateCredentialStatus = async (type: string, certificate: Certificate) => {
  try {
    const response = await api.post(
      `/tapatybe/api/v1/crm/credentials/us-${type.toLowerCase()}-certificate/create-status`,
      {
        data: {
          account_id: certificate.account_id,
          credential_id: certificate.credential_id,
          credential_status_type_id:
            certificate.current_credential_status.credential_status.credential_status_type
              .credential_status_type_id,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const createBackgroundCheckStatus = async (
  type: string,
  backgroundCheck: Facis3BackgroundScreening
) => {
  try {
    let urlType = "facis3-background-screenings";
    if (type === "tenpanel") {
      urlType = "tenpanel-drug-checks";
    }
    const response = await api.post(
      `/tapatybe/api/v1/crm/background-checks/${urlType}/create-status`,
      {
        data: {
          account_id: backgroundCheck.account_id,
          background_check_id: backgroundCheck.background_check_id,
          background_check_status_type_id:
            backgroundCheck.current_background_check_status.background_check_status
              .background_check_status_type.background_check_status_type_id,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getPendingVaccinations = async () => {
  try {
    const response = await api.get<APIResponse<Vaccination[]>>(
      `/tapatybe/api/v1/crm/health-records/vaccinations?health_record_status_source_key=para&health_record_status_type_key=pending_verification`
    );
    return response.data.data.sort((a, b) => {
      if (a.vaccination_date > b.vaccination_date) {
        return 1;
      }
      return -1;
    });
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const createVaccinationCredentialStatus = async (vaccination: Vaccination) => {
  try {
    const response = await api.post(
      `/tapatybe/api/v1/crm/health-records/vaccinations/create-status`,
      {
        data: {
          account_id: vaccination.account_id,
          health_record_id: vaccination.health_record_id,
          health_record_status_type_id:
            vaccination.current_health_record_status.health_record_status.health_record_status_type
              .health_record_status_type_id,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getPendingScreenings = async () => {
  try {
    const response = await api.get(
      `/tapatybe/api/v1/crm/health-records/medical-screenings?health_record_status_source_key=para&health_record_status_type_key=pending_verification`
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getNurseFacis3BackgroundScreenings = async (nurseId: string) => {
  try {
    const response = await api.get<APIResponse<Facis3BackgroundScreening[]>>(
      `/tapatybe/api/v1/account/background-checks/facis3-background-screenings?account_id=${nurseId}`
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const createNurseFacis3BackgroundScreening = async (nurseId: string, expiry_date: string) => {
  try {
    const response = await api.post<APIResponse<Facis3BackgroundScreening[]>>(
      `/tapatybe/api/v1/account/background-checks/facis3-background-screening`,
      {
        data: {
          account_id: nurseId,
          expiry_at: expiry_date,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const createNurse10PanelDrugCheck = async (nurseId: string, expiry_date: string) => {
  try {
    const response = await api.post<APIResponse<Facis3BackgroundScreening[]>>(
      `/tapatybe/api/v1/account/background-checks/tenpanel-drug-check`,
      {
        data: {
          account_id: nurseId,
          expiry_at: expiry_date,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getPendingFacis3BackgroundScreenings = async () => {
  try {
    const response = await api.get<APIResponse<Facis3BackgroundScreening[]>>(
      `/tapatybe/api/v1/crm/background-checks/facis3-background-screenings?background_check_status_source_key=checkr&background_check_status_type_key=pending`
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getNurse10PanelDrugChecks = async (nurseId: string) => {
  try {
    const response = await api.get<APIResponse<Facis3BackgroundScreening[]>>(
      `/tapatybe/api/v1/account/background-checks/tenpanel-drug-checks?account_id=${nurseId}`
    );
    return response.data.data ? response.data.data : [];
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

const getPending10PanelDrugChecks = async () => {
  try {
    const response = await api.get<APIResponse<Facis3BackgroundScreening[]>>(
      `/tapatybe/api/v1/crm/background-checks/tenpanel-drug-checks?background_check_status_source_key=checkr&background_check_status_type_key=pending`
    );
    return response.data.data ? response.data.data : [];
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const nurseService = {
  getNurses,
  getNurse,
  getNursePhoto,
  getNurseCertificates,
  getNurseLicenses,
  getNurseVaccinations,
  getCredentialStatusTypes,
  getHealthRecordStatusTypes,
  getBackgroundChecksStatusTypes,
  getPendingLicenses,
  createLicenseCredentialStatus,
  getLicenseImages,
  getPendingCertificates,
  createCertificateCredentialStatus,
  createBackgroundCheckStatus,
  getPendingVaccinations,
  createVaccinationCredentialStatus,
  getPendingScreenings,
  getNurseFacis3BackgroundScreenings,
  getPendingFacis3BackgroundScreenings,
  getNurse10PanelDrugChecks,
  getPending10PanelDrugChecks,
  createNurseFacis3BackgroundScreening,
  createNurse10PanelDrugCheck,
};
